<template>
  <div class="timer">
    <span class="icon-lock" v-if="status===ORDER_STATUS.NEW.value"></span>
    <span class="text">订单{{ORDER_STATUS[status]?ORDER_STATUS[status].label:''}}</span>
  </div>
</template>

<script>
import { ORDER_STATUS } from '@/constants/order'

export default {
  props: {
    status: [Number, String],
  },
  data() {
    return {
      ORDER_STATUS,
    }
  },
}
</script>

<style lang="stylus" scoped>
.timer
  display flex
  justify-content center
  align-items center
  width 100%
  margin 60px 0 30px 0
  z-index 101
  .icon-lock
    width 44px
    height 44px
    background-image url('../images/lock.png')
    background-size 100% 100%
    display inline-block
    margin-right 20px
  .text
    font-family PingFangSC-Medium
    font-size 32px
    font-weight normal
    font-stretch normal
    letter-spacing 0px
    color #ffffff
    display inline-block
</style>