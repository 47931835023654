import wx from 'weixin-js-sdk'
import WxApi from '@/models/wx'
const wxApi = new WxApi()

export default {
  /* 初始化wxjsdk各种接口 */
  init(apiList = ['chooseWXPay'], openTagList = []) {
    let url = encodeURIComponent(window.location.href)
    return new Promise((resolve, reject) => {
      wxApi.querywxConfig(url).then((res) => {
        if (res.status === 0 && res.data.appId) {
          wx.config({
            appId: res.data.appId,
            timestamp: res.data.timestamp,
            nonceStr: res.data.nonceStr,
            signature: res.data.signature,
            jsApiList: apiList,
            openTagList: openTagList,
          })
          wx.ready(() => {
            wx.checkJsApi({
              jsApiList: ['chooseWXPay'],
              success: (res) => {
                console.log('checked api:', res)
              },
              fail: (err) => {
                console.log('check api fail:', err)
              },
            })
            resolve(true)
          }),
            wx.error((err) => reject(err.errMsg))
        } else {
          reject(res)
        }
      })
    })
  },
  //判断是否是在微信环境里面
  isWeixin: () => {
    var ua = navigator.userAgent.toLowerCase()
    return ua.indexOf('micromessenger') != -1
  },
}
