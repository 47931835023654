import HTTP from '@/utils/http'

class WX extends HTTP {
  /**
   * 获取签名
   */
  querywxConfig(url) {
    return this.get({
      url: `/usercenter/wechat/sign?url=${url}`,
    })
  }
  /**
   * 获取appId
   */
  getAppId(data) {
    return this.get({
      url: '/usercenter/wechat/init',
      data,
    })
  }
  /***
   *
   * 获取unionid openid等
   */
  getWXInfo(code) {
    return this.get({
      url: `/usercenter/wechat/login/bycode/${code}`,
    })
  }
}
export default WX
