// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/images/bg1@3x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".coupon-list[data-v-48b2e7aa]{width:100%;padding:.26667rem}.coupon-list .item[data-v-48b2e7aa]{width:100%;margin:.26667rem auto;height:2.02667rem;border-radius:.26667rem;position:relative;display:flex;align-items:center;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;margin-bottom:0!important;font-family:PingFangSC-Medium;font-weight:400;font-stretch:normal;color:#fff2dd}.coupon-list .item .left[data-v-48b2e7aa]{margin-right:.26667rem;width:2.66667rem;text-align:center}.coupon-list .item .left .icon-money[data-v-48b2e7aa]{font-size:.32rem}.coupon-list .item .left .num[data-v-48b2e7aa]{font-size:.53333rem}.coupon-list .item .left .text[data-v-48b2e7aa]{font-size:.29333rem}.coupon-list .item .middle[data-v-48b2e7aa]{margin-left:.26667rem}.coupon-list .item .middle .coupon_middle[data-v-48b2e7aa]{font-size:.42667rem}.coupon-list .item .middle .text[data-v-48b2e7aa]{font-size:.29333rem}.coupon-list .item .right[data-v-48b2e7aa]{position:absolute;top:50%;right:.26667rem;transform:translateY(-50%);display:flex;flex-direction:column;align-items:center}.coupon-list .item .right input[data-v-48b2e7aa]{visibility:hidden}.coupon-list .item .right .num[data-v-48b2e7aa]{font-size:.32rem;display:flex;align-items:center;justify-content:space-between}.coupon-list .item .right .choose[data-v-48b2e7aa]{margin-top:.13333rem;margin-left:.66667rem;width:.48rem;height:.48rem}", ""]);
// Exports
module.exports = exports;
