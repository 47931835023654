// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../images/lock.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".timer[data-v-3e7d71d8]{display:flex;justify-content:center;align-items:center;width:100%;margin:.8rem 0 .4rem 0;z-index:101}.timer .icon-lock[data-v-3e7d71d8]{width:.58667rem;height:.58667rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;display:inline-block;margin-right:.26667rem}.timer .text[data-v-3e7d71d8]{font-family:PingFangSC-Medium;font-size:.42667rem;font-weight:400;font-stretch:normal;letter-spacing:0;color:#fff;display:inline-block}", ""]);
// Exports
module.exports = exports;
