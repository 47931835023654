<!-- 在微信浏览器中选用支付宝支付时候的蒙层 -->
<template>
  <div class="share" @click="close">
    <img class="close" src="./images/close.png" alt />
    <img class="arrows" src="./images/arrows.png" alt />
    <div class="block">
      <div class="item">
        <span>点击右上角的</span>
        <img class="apostrophe" src="./images/apostrophe.png" alt />
        <span>按钮</span>
      </div>
      <div class="item">
        <span>选择</span>
        <img class="browser" src="./images/browser.png" alt />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
<style lang='stylus' scoped>
@import './share.styl'
</style>