<template>
  <div class="pay_button">
    <div class="pay_button_item">
      <span class="pay_button_item_text">需付</span>
      <span class="money">¥</span>
      <span class="pay_button_item_money">{{
        detail.payAmount ? detail.payAmount.CASH : detail.orderTotalCalc
      }}</span>
      <br />
      <span class="total">总¥{{ detail.orderTotal }}</span>
    </div>
    <div class="right">
      <button
        class="pay_button_item pay_button_item_button"
        :class="{
          pay_button_item_button_no:
            detail.orderStatus !== ORDER_STATUS.NEW.value,
        }"
        @click="onPay"
      >
        {{
          detail.orderStatus === ORDER_STATUS.NEW.value
            ? '立即支付'
            : ORDER_STATUS[detail.orderStatus]
            ? ORDER_STATUS[detail.orderStatus].label
            : '已完成'
        }}
      </button>
    </div>
  </div>
</template>

<script>
import { ORDER_STATUS } from '@/constants/order'
export default {
  props: {
    detail: [Object],
  },
  data() {
    return {
      ORDER_STATUS,
    }
  },
  methods: {
    onPay() {
      this.$emit('go:pay')
    },
  },
}
</script>

<style lang="stylus" scoped>
.pay_button
  width 100%
  height 108px
  background-color #ffffff
  display flex
  align-items center
  padding 0 30px
  justify-content space-between
  position relative
  .pay_button_item
    align-items center
    line-height 1.2
    .total
      font-size 20px
      color #888
    .pay_button_item_text
      font-family PingFangSC-Regular
      font-size 26px
      font-weight normal
      font-stretch normal
      letter-spacing 0px
      color #666666
      display inline-block
    .money
      font-family PingFangSC-Regular
      font-size 28px
      font-weight normal
      font-stretch normal
      letter-spacing 0px
      color #ff5716
    .pay_button_item_money
      font-family PingFangSC-Medium
      font-size 42px
      font-weight normal
      letter-spacing 0px
      color #ff5716
      display inline-block
  .pay_button_item_button
    width 260px
    height 80px
    background-color #ff8300
    border-radius 40px
    font-family PingFangSC-Regular
    font-size 32px
    font-weight normal
    font-stretch normal
    letter-spacing 0px
    color #ffffff
    text-align center
    line-height 80px
  .pay_button_item_button_no
    width 260px
    height 80px
    background-color #bbbbbb
    border-radius 40px
    font-family PingFangSC-Regular
    font-size 32px
    font-weight normal
    font-stretch normal
    letter-spacing 0px
    color #ffffff
    text-align center
    line-height 80px
  .right
    display flex
    align-items center
    .btn-detail
      display flex
      justify-content center
      align-items center
      margin-right 20px
      font-size 26px
      .detail-text
        width 60px
      .detail-icon
        width 15px
</style>
