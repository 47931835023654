<!-- 待支付详情页面 -->
<template>
    <div class="payment">
        <div class="content">
            <OrderStatus :status="orderInfo.orderStatus" />
            <!-- 学员信息 -->
            <UserInfo class="item" :user="{ phone: orderInfo.userPhone, name: orderInfo.userName }" />
            <!-- vip缴费，不需要展示下面两个模块 -->
            <template v-if="isVip">
                <VipInfo :orderInfo="orderInfo" />
            </template>
            <template v-else>
                <!-- 关卡信息 -->
                <Level class="item" v-if="orderInfo.products" :type="orderInfo.app" :products="orderInfo.products" />
                <!-- 代金券 （优惠券，Lily币，账户余额） -->
                <Calc v-if="orderInfo.app !== AppType.TRIAL" class="item" @post:calc="onCalc" :detail="orderInfo" />
            </template>
            <!-- 支付方式   1微信 2支付宝 -->
            <Way class="item" @post:way="onPayWay" :detail="orderInfo"
                v-if="orderInfo.orderStatus === ORDER_STATUS.NEW.value" />
        </div>

        <div class="bottom">
            <!-- 协议 -->
            <Agreement @post:agreement="onArgeement" :detail="orderInfo"
                v-if="orderInfo.orderStatus === ORDER_STATUS.NEW.value" />
            <!-- 底部支付 -->
            <Pay @go:pay="onPay" :detail="orderInfo" />
        </div>
        <!-- 在微信浏览器中选用支付宝支付时候的蒙层 -->
        <Share v-if="showShare" @close="() => (showShare = false)"></Share>
        <!-- 支付宝支付表单容器 -->
        <div class="alipay-form" v-html="alipayForm"></div>
        <!-- 优惠提示 -->
    </div>
</template>

<script>
import OrderStatus from './components/order-status.vue'
import UserInfo from './components/user-info.vue'
import Level from './components/level.vue'
import Calc from './components/calc.vue'
import Way from './components/way.vue'
import Agreement from './components/agreement.vue'
import VipInfo from './components/vip-info.vue'
import Pay from './components/pay.vue'
import Share from './components/share'
import OrderModel from '@/models/order'
import { PAY_WAY } from '@/constants/pay'
import { ORDER_STATUS, AppType } from '@/constants/order'
import wx from 'weixin-js-sdk'
import wechat from '@/utils/wx/wx'
import wxLogin from '@/utils/wx/wxLogin'
import WxApi from '@/models/wx'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('user')
const wxApi = new WxApi()
const order = new OrderModel()

export default {
    data () {
        return {
            PAY_WAY,
            AppType,
            ORDER_STATUS,
            orderInfo: {},
            payBag: {
                payType: 0,
                isAgree: false,
            },
            calcData: null,
            alipayForm: '',
            showShare: false,
            tempDetail: {},
        }
    },
    components: {
        OrderStatus,
        UserInfo,
        Level,
        Calc,
        Way,
        Agreement,
        Pay,
        Share,
        VipInfo,
    },
    computed: {
        ...mapGetters(['wx']),
        isVip () {
            // if (process.env.VUE_APP_ENV !== 'prod') {
            //     return false
            // }
            return this.orderInfo.app === this.AppType.VIP
        }
    },
    created () {
        this._detail()
    },
    mounted () {
        if (wechat.isWeixin()) {
            wechat.init()
            this._auth()
        }
    },
    methods: {
        onCalc ({ calcData, checkCoupons }) {
            let total = this.orderInfo.orderTotal * 100
            this.calcData = calcData
            // 减优惠券
            if (calcData.coupons && calcData.coupons.length) {
                for (let i = 0; i < checkCoupons.length; i++) {
                    total -= checkCoupons[i].amount * 100
                }
            }

            // 减余额
            if (calcData.balance) {
                total -= calcData.balance * 100
            }

            this.orderInfo.orderTotalCalc = total / 100 > 0 ? total / 100 : 0
        },
        onPayWay (way) {
            this.payBag.payType = way
        },
        onArgeement (isAgree) {
            this.payBag.isAgree = isAgree
        },
        async onPay () {
            if (!this._canPay()) {
                return
            }

            if (!this.tempDetail.payAmount) {
                await this._prepay()
            }

            this._pay()
        },
        _auth () {
            if (this.wx.openId) {
                return
            }

            let url = window.location.href
            if (url.indexOf('code=') > -1) {
                let code = this.$common.getQueryString('code')
                wxApi.getWXInfo(code).then((res) => {
                    if (res.status == 0) {
                        this.$store.commit('user/WX', {
                            openId: res.data.openId,
                            unionId: res.data.unionId,
                        })
                    }
                })
            } else {
                //授权
                wxLogin('dayuanauth')
            }
        },
        async _prepay () {
            this.$loading(true)
            return order
                .prepay({
                    ...this.calcData,
                    orderId: this.$route.query.orderId,
                })
                .then((res) => {
                    this.$loading(false)
                    this.orderInfo.orderTotal = res.data.cash.amount
                    this.orderInfo.deductCouponAmout = res.data.coupon.amount
                    this.orderInfo.deductBalance = res.data.blance.amount
                    return true
                })
                .catch(() => {
                    this.$loading(false)
                    return false
                })
        },
        _pay () {
            this.$loading(true)
            order
                .pay({
                    orderId: this.$route.query.orderId,
                    type: this.payBag.payType,
                    openId: this.wx.openId,
                })
                .then((res) => {
                    this.$loading(false)
                    switch (this.payBag.payType) {
                        case PAY_WAY.WECHAT_H5:
                            this._WECHAT_H5(res.data)
                            break
                        case PAY_WAY.ALIPAY_WAP:
                            this._ALIPAY_WAP(res.data)
                            break
                        case PAY_WAY.WECAHT_JSAPI:
                            this._WECAHT_JSAPI(res.data)
                            break
                    }
                }).catch(() => {
                    this.$loading(false)
                })
        },
        _WECHAT_H5 (data) {
            window.location.href =
                data.mwebUrl +
                '&redirect_url=' +
                encodeURIComponent(
                    `https://${process.env.VUE_APP_ENV === 'prod' ? '' : process.env.VUE_APP_ENV + '-'}pay.dayuan1997.com?_=${this.$route.query.orderId}`
                )
        },
        _ALIPAY_WAP (data) {
            this.alipayForm = data.body
            setTimeout(() => {
                document
                    .querySelector('.alipay-form')
                    .querySelector('form')
                    .submit()
            }, 200)
        },
        _WECAHT_JSAPI (data) {
            let _this = this
            wx.chooseWXPay({
                // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                timestamp: data.timeStamp,
                // 支付签名随机串，不长于 32 位
                nonceStr: data.nonceStr,
                // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                package: data.package,
                // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                signType: data.signType,
                // 支付签名
                paySign: data.paySign,
                // 支付成功后的回调函数
                success: function (res) {
                    // res.errMsg === 'chooseWXPay:ok'方式判断前端返回,微信团队郑重提示：
                    // res.errMsg将在用户支付成功后返回ok，但并不保证它绝对可靠， 切记。
                    if (res.errMsg === 'chooseWXPay:ok') {
                        // _this.$toast(res, "success");
                        // _this.rotationGetDetail()
                        // TODO：处理支付后的状态
                    }
                },
                // 支付取消回调函数
                cencel: function (res) {
                    _this.$toast(res, 'fail')
                },
                // 支付失败回调函数
                fail: function (res) {
                    _this.$toast(res, 'fail')
                },
            })
        },
        _canPay () {
            if (!this.payBag.isAgree) {
                this.$toast('请阅读并同意勾选报名协议', 'fail')
                return false
            }

            if (!this.payBag.payType) {
                this.$toast('请选择支付方式', 'fail')
                return false
            }

            if (
                wechat.isWeixin() &&
                this.payBag.payType === this.PAY_WAY.ALIPAY_WAP
            ) {
                this.showShare = true
                return false
            }
            return true
            // 检测如果有优惠券没使用，则弹窗提示
        },
        _detail () {
            order.detail({ orderId: this.$route.query.orderId }).then((res) => {
                this.orderInfo = {
                    orderTotalCalc: res.data.orderTotal,
                    ...res.data,
                }
                if (this.orderInfo.orderStatus === this.ORDER_STATUS.NEW.value) {
                    this._checkOrder()
                } else {
                    this._leave(res.data)
                }
            })
        },
        _checkOrder () {
            this.timer = setInterval(() => {
                order.detail({ orderId: this.$route.query.orderId }).then((res) => {
                    this.tempDetail = res.data
                    if (res.data.orderStatus !== this.ORDER_STATUS.NEW.value) {
                        this._leave(res.data)
                        clearInterval(this.timer)
                        this.timer = null
                        return
                    }
                })
            }, 1500)
        },
        _leave (detail) {
            this.$toast(
                `您的订单${this.ORDER_STATUS[detail.orderStatus].label
                }，\n\n即将在3秒后跳转`,
                'fail',
                2000
            )
            setTimeout(() => {
                if (this.$route.query.returnUrl) {
                    window.location.replace(this.$route.query.returnUrl)
                } else if (this.orderInfo.app === this.AppType.VIP) {
                    window.location.href = `https://${process.env.VUE_APP_ENV === 'prod' ? '' : process.env.VUE_APP_ENV + '-'}activity.dayuan1997.com/address`
                } else {
                    this.$router.replace({
                        path: `/?orderId=${this.$route.query.orderId}`,
                    })
                }
            }, 3000)
        },
    },
    destroyed () {
        if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
        }
    },
}
</script>
<style lang="stylus" scoped>
@import 'index.styl'
</style>
