import HTTP from '@/utils/http'

export default class AccountModel extends HTTP {
  /**
   * 订单详情
   * @param {*} params
   */
  accountInfo() {
    return this.get({
      url: `/account/balance`,
    })
  }
}
