<template>
    <div class="protocol">
        <label for="agreement">
            <img class="icon-select" v-if="isAgree" src="../images/selected.png" alt />
            <img class="icon-select" v-else src="../images/unselected.png" alt />
            <input type="checkbox" id="agreement" name="agreement" v-model="isAgree" />
            <span class="content">阅读并同意</span>
        </label>
        <span class="agreement" @click="onViewAgreement">《支付协议》</span>
    </div>
</template>

<script>

export default {
    props: {
        type: [Number, String],
        detail: Object
    },
    data () {
        return {
            isAgree: false,
        }
    },
    mounted () {
        this.$emit('post:agreement', this.isAgree)
    },
    watch: {
        isAgree () {
            this.$emit('post:agreement', this.isAgree)
        },
    },
    methods: {
        onViewAgreement () {
            let code = ''
            switch (this.detail.app) {
                case "BHBL":
                    code = '10'
                    break;
                case "VIP":
                    code = '16'
                    break;

                default:
                    break;
            }
            window.location.href = `https://cms.dayuan1997.com/api/cmscore/protocol?type=${code}`

            // this.$router.push(
            //   `/agreement?type=${
            //     this.type == ORDER_TYPE.NEW || this.type == ORDER_TYPE.INSERT ? 9 : 10
            //   }`
            // )
        },
    },
}
</script>

<style lang="stylus" scoped>
.protocol
  width 100%
  height 80px
  line-height 40px
  // margin-top 38px
  display flex
  align-items center
  padding-left 36px
  background-color #f8f8f8
  label
    display inline-flex
    align-items center
  input
    visibility hidden
    width 3px
  .icon-select
    width 32px
    height 32px
    display inline-block
    align-items center
    margin-right 10px
  .content
    align-items center
    font-family PingFangSC-Regular
    font-size 24px
    font-weight normal
    font-stretch normal
    letter-spacing 0px
    color #999999
  .agreement
    color #2893FF
</style>