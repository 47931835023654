import { render, staticRenderFns } from "./user-info.vue?vue&type=template&id=597c2b86&scoped=true"
import script from "./user-info.vue?vue&type=script&lang=js"
export * from "./user-info.vue?vue&type=script&lang=js"
import style0 from "./user-info.vue?vue&type=style&index=0&id=597c2b86&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "597c2b86",
  null
  
)

export default component.exports