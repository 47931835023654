/**
 * 支付途径
 */
export const PAY_WAY = {
  /**
   * 阿里网页支付
   */
  ALIPAY_WAP: 3,
  /**
   * 微信网页支付（脱离微信环境）
   */
  WECHAT_H5: 5,
  /**
   * 微信JSAPI支付 (微信环境)
   */
  WECAHT_JSAPI: 4,
}

/**
 * 支付方式
 */
export const PAY_TYPE = {
  /**
   * 现金（支付宝、微信）
   */
  CASH: 'CASH',
  /**
   * LILY币
   */
  LIY: 'LIY',
  /**
   * 优惠
   */
  COUPON: 'COUPON',
  /**
   * 余额
   */
  BALANCE: 'BALANCE',
}

/**
 * 支付状态
 */
export const PAY_STATUS = {
  /**
   * 待支付
   */
  PAY: 'PAY',
  /**
   * 支付成功
   */
  PAY_SUCCESS: 'PAY_SUCCESS',
  /**
   * 退款
   */
  REFUND: 'REFUND',
  /**
   * 退款成功
   */
  REFUND_SUCCESS: 'REFUND_SUCCESS',
}

export const PAY_STATUS_LABEL = {
  /**
   * 待支付
   */
  PAY: '待支付',
  /**
   * 支付成功
   */
  PAY_SUCCESS: '支付成功',
  /**
   * 退款
   */
  REFUND: '退款',
  /**
   * 退款成功
   */
  REFUND_SUCCESS: '退款成功',
}
