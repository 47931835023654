<template>
  <div class="way">
    <label class="way-item" v-for="(way,index) in ways" :key="index" :for="way.key">
      <div class="left">
        <input type="radio" name="pay-way" :id="way.key" @change="onCheck(way)" />
        <img :src="way.icon" class="icon-pay" alt />
        <span class="way-name">{{way.name}}</span>
      </div>
      <img v-if="selectWay===way.key" src="../images/selected-light.png" class="icon-select" alt />
      <img v-else src="../images/unselected.png" class="icon-select" alt />
    </label>
  </div>
</template>

<script>
import { PAY_WAY } from '../../../constants/pay'

export default {
  data() {
    return {
      selectWay: '',
      ways: [
        {
          key: PAY_WAY.WECHAT_H5,
          name: '微信支付',
          icon: require('../images/wechat.png'),
          checked: false,
        },
        {
          key: PAY_WAY.ALIPAY_WAP,
          name: '支付宝',
          icon: require('../images/alipay.png'),
          checked: false,
        },
      ],
    }
  },
  methods: {
    onCheck(way) {
      this.selectWay = way.key
      if (this._isWeChat() && this.selectWay === PAY_WAY.WECHAT_H5) {
        this.$emit('post:way', PAY_WAY.WECAHT_JSAPI)
        return
      }
      this.$emit('post:way', this.selectWay)
    },
    _isWeChat() {
      let ua = navigator.userAgent.toLowerCase()
      let isWeixin = ua.indexOf('micromessenger') != -1
      if (isWeixin) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.way
  padding 30px
  .way-item
    height 100px
    line-height 100px
    display flex
    align-items center
    justify-content space-between
    .left
      display flex
      align-items center
      input
        visibility hidden
        width 2px
      .icon-pay
        width 42px
        height 38px
        display inline-block
        align-items center
      .way-name
        font-family PingFangSC-Regular
        font-size 32px
        font-weight normal
        font-stretch normal
        letter-spacing 0px
        color #333333
        margin-left 13px
    .icon-select
      width 38px
      height 38px
      display inline-block
      align-items center
</style>