import HTTP from '@/utils/http'

class Coupon extends HTTP {
  // /**
  //  * 兑换抵金券
  //  */
  // exchangeArrive(data) {
  //   return this.post({
  //     url: '/coupon/user/coin/exchange',
  //     data,
  //   })
  // }
  // /**
  //  * 获取抵金券和lily币兑换券
  //  */
  // getAllCoupons(userId) {
  //   return this.get({
  //     url: `/ordercore/account/v1?userId=${userId}`,
  //     header: {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //   })
  // }

  /**
   * 获取优惠券
   * @param {*} params
   */
  list(params) {
    return this.get({
      url: `/coupon/user/list`,
      params,
    })
  }

  // /**
  //  * 核算金额
  //  */
  // calc(data) {
  //   return this.post({
  //     url: '/ordercore/calc/v1',
  //     data,
  //   })
  // }
}
export default Coupon
