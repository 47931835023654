<template>
  <div class="coupon-list" v-if="list.length > 0">
    <div class="item" v-for="(item, index) in list" :key="index">
      <div class="left">
        <div class="top">
          <span class="icon-money">¥</span>
          <span class="num">{{ item.amount }}</span>
        </div>
        <div class="text">适用全部关卡</div>
      </div>
      <div class="middle">
        <div class="coupon_middle">抵金券</div>
        <div class="text">
          有效期:{{ formatdt(item.beginTime) }}-{{ formatdt(item.endTime) }}
        </div>
      </div>
      <label class="right" :id="`coupon-item_${item.id}`">
        <input
          type="checkbox"
          v-model="item.checked"
          :value="item.id"
          @change="onCheck(index)"
        />
        <div class="num">可抵{{ item.amount }}元</div>
        <img v-if="item.checked" class="choose" src="../images/sel.png" />
        <img v-else class="choose" src="../images/unsel.png" />
      </label>
    </div>
    <Popout v-show="isPrpout">
      <p class="delog">选择的优惠券总面额已超过订单可抵扣金额！</p>
    </Popout>
  </div>
</template>

<script>
import Popout from '@/components/popout/index'

export default {
  props: {
    list: [Array],
  },
  data() {
    return {
      isPrpout: false,
      coupons: {},
      whiteImgUrl: require('../../../../../assets/images/whiteUn@3x.png'),
      redImgUrl: require('../../../../../assets/images/redUn@3x.png'),
      couponAmount: '', //抵扣总金额
    }
  },
  components: {
    Popout,
  },
  methods: {
    formatdt(timezone) {
      const time = new Date(timezone)
      return `${time.getFullYear()}年${time.getMonth() +
        1}月${time.getDate()}日`
    },
    //弹出框的确定按钮
    onConfirm() {
      this.isPrpout = false
    },
    //抵金券选中事件
    onCheck(index) {
      this.$emit('post:coupon', { index, checked: this.list[index].checked })
    },
  },
}
</script>
<style lang="stylus" scoped>
@import './list.styl'
</style>
