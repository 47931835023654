// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./images/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".payment[data-v-3c6da24e]{height:100vh}.payment[data-v-3c6da24e],.payment .content[data-v-3c6da24e]{width:100%;background-color:#f8f8f8}.payment .content[data-v-3c6da24e]{display:flex;flex-direction:column;align-items:center;padding-bottom:2.93333rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:100%;background-position:top}.payment .content>.item[data-v-3c6da24e]{width:9.2rem;margin-top:.4rem;background-color:#fff;box-shadow:0 .08rem .16rem 0 rgba(0,0,0,.05);border-radius:.26667rem}.payment .bottom[data-v-3c6da24e]{position:fixed;bottom:0;left:0;right:0;z-index:3}", ""]);
// Exports
module.exports = exports;
