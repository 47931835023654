<template>
  <div class="coupon">
    <div class="content">
      <CouponList :list="coupons" @post:coupon="onCheckCoupon"></CouponList>
    </div>
    <div class="bottom">
      <div class="item">
        <span class="num">已选 {{ checkList.length }} 张，可减：</span>
        <span class="icon-money">¥</span>
        {{ couponAmount }}
      </div>
      <button class="btn-yes" @click="onSubmit">确定</button>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import CouponList from './list'
import Coupon from '@/models/coupon'

const { mapGetters } = createNamespacedHelpers('user')
const coupon = new Coupon()

export default {
  props: {
    checkCoupons: [Array],
  },
  data() {
    return {
      couponAmount: 0, //可抵扣的金额
      pays: {},
      coupons: [],
      checkList: [],
    }
  },
  components: {
    CouponList,
    // CouponDialog,
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this._list()
  },
  methods: {
    _list() {
      coupon
        .list({ userId: this.userInfo.id, page: 1, size: 1000 })
        .then((res) => {
          if (!res.data) {
            return
          }

          // 只能选择未使用优惠券
          res.data.forEach((coupon) => {
            const now = Date.now()
            if (coupon.status === 0 && now >= coupon.beginTime && now <= coupon.endTime) {
              this.coupons.push(coupon)
            }
          })
          for (let i = 0;i < this.checkCoupons.length;i++) {
            for (let j = 0;j < this.coupons.length;j++) {
              if (this.checkCoupons[i].id === this.coupons[j].id) {
                this.coupons[j].checked = true
                return false
              }
            }
          }
        })
    },
    //渲染抵扣金额
    getCouponAmount(parameters) {
      this.couponAmount = parameters.couponAmount
      this.nums = parameters.nums
      this.pays = parameters.pays
    },
    onCheckCoupon({ index, checked }) {
      this.checkList = []
      this.$set(this.coupons[index], "checked", checked)

      this.couponAmount = 0
      for (let i = 0;i < this.coupons.length;i++) {
        if (this.coupons[i].checked) {
          this.couponAmount += this.coupons[i].amount
          this.checkList.push(this.coupons[i])
        }
      }
    },
    //确定抵扣
    onSubmit() {
      console.log(this.checkList)
      this.$emit('checked:coupon', this.checkList)
    },
  },
}
</script>
<style lang="stylus" scoped>
@import './index.styl'
</style>
