<template>
    <div class="products">
        <div class="product">
            <span class="title">LILY老师讲故事 一 会员招募</span>
            <div class="books">
                <section class="book">
                    <div class="price">
                        <span class="num">
                            单价：¥{{ orderInfo.orderTotal }}
                        </span>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        orderInfo: Object,
    },
}
</script>

<style lang="stylus" scoped>
.products
  width 690px
  background-color #ffffff
  border-radius 20px
  margin 0 auto
  margin-top 20px
  padding 30px
  .product
    display flex
    flex-direction: column
    padding 15px 0
    box-shadow 0px 1px 2px 0px rgba(0, 0, 0, 0.05)
    &:last-child
      box-shadow none
    .title
      align-items center
      font-family PingFangSC-Regular
      font-size 28px
      font-weight 500
      font-stretch normal
      letter-spacing 0px
      color #333
      margin 0 0 10px 0 
    .books
      display: flex
      flex-direction: column
    
      .book
        display: flex
        justify-content: space-between
        flex-direction: column
        margin 5px 0 
        .price,.top
          display: flex
          justify-content: space-between
          align-items:center
          color:  #999
        .top 
          .b-title
            font-size 26px
            color #333
              
</style>