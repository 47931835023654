<template>
  <div class="calc">
    <div class="title">待支付金额：¥{{ detail.orderTotalCalc }}</div>
    <!-- 抵金券 -->
    <div class="item">
      <span class="left">抵金券</span>
      <div class="right">
        <template v-if="isLogin || detail.orderStatus !== ORDER_STATUS.NEW.value">
          <!-- 已锁定 -->
          <template v-if="detail.payStatus">
            <span class="sale" v-if="detail.payAmount && detail.payAmount.COUPON">抵扣¥{{
              detail.payAmount.COUPON }}</span>
            <span class="gray" v-else>抵扣¥0</span>
          </template>
          <!-- 未锁定 -->
          <template v-else>
            <span v-if="checkCoupons.length <= 0 && coupons && coupons.length" class="active"
              @click="onChooseCoupon">未选用抵金券{{ coupons.length }}张可用</span>
            <span class="sale" v-else-if="checkCoupons && checkCoupons.length > 0" @click="onChooseCoupon">-¥{{
              couponAmount }}</span>
            <span v-else class="disabled">暂无可用抵金券</span>
            <img v-if="coupons && coupons.length" class="icon-more" src="../images/more.png" alt />
          </template>
        </template>
        <template v-else>
          <span class="login" @click="onLogin">登录获取</span>
        </template>
      </div>
    </div>
    <!-- 余额 -->
    <div class="item">
      <span class="left">账户余额</span>
      <label class="right" for="balance">
        <template v-if="isLogin || detail.orderStatus !== ORDER_STATUS.NEW.value">
          <!-- 已锁定 -->
          <template v-if="detail.payStatus">
            <span class="sale" v-if="detail.payAmount && detail.payAmount.BLANCE">抵扣¥ {{ detail.payAmount.BLANCE }}</span>
            <span class="gray" v-else>抵扣¥0</span>
          </template>
          <!-- 未锁定 -->
          <template v-else>
            <input type="checkbox" id="balance" v-model="isBalance" @change="onBalance" />
            <span v-if="detail.deductBalance" class="sale">抵扣{{ detail.deductBalance }}元</span>
            <span class="gray">可用余额{{ accountInfo.real || 0 }}元</span>
            <img class="icon-select" v-if="isBalance" src="../images/selected-light.png" alt />
            <img class="icon-select" v-else src="../images/unselected.png" alt />
          </template>
        </template>
        <template v-else>
          <span class="login" @click="onLogin">登录获取</span>
        </template>
      </label>
    </div>
    <!-- 选择优惠券 -->
    <div class="mask" v-if="isLogin" v-show="isChooseCoupon">
      <div class="mask-content">
        <span @click="onChooseCoupon" class="btn-cancel">取消</span>
        <CouponComp @checked:coupon="onCoupon" :check-coupons="checkCoupons" />
      </div>
    </div>
  </div>
</template>

<script>
import { ORDER_STATUS } from '@/constants/order'
import AccountModel from '@/models/account'
import CouponModel from '@/models/coupon'
import { createNamespacedHelpers } from 'vuex'
import CouponComp from './coupon/index.vue'
const { mapGetters } = createNamespacedHelpers('user')
const account = new AccountModel()
const coupon = new CouponModel()

export default {
  props: {
    detail: [Object],
  },
  data() {
    return {
      ORDER_STATUS,
      isBalance: false,
      accountInfo: {},
      coupons: [],
      checkCoupons: [],
      couponAmount: 0,
      isChooseCoupon: false,
    }
  },
  components: { CouponComp },
  computed: {
    ...mapGetters(['isLogin', 'userInfo']),
  },
  mounted() {
    if (this.isLogin) {
      this._account()
      this._coupon()
    }
  },
  methods: {
    onChooseCoupon() {
      this.isChooseCoupon = !this.isChooseCoupon
    },
    onCoupon(coupon) {
      this.checkCoupons = coupon
      this.couponAmount = 0
      // 计算抵扣金额
      for (let i = 0;i < this.checkCoupons.length;i++) {
        this.couponAmount += this.checkCoupons[i].amount
      }
      this._prepay()
      this.onChooseCoupon()
    },
    onBalance() {
      this._prepay()
    },
    onLogin() {
      this.$router.push({
        path: `/login?redirect_uri=${encodeURIComponent(window.location.href)}`,
      })
    },
    _prepay() {
      const postData = {
        coupons: this.checkCoupons.map((item) => {
          return item.id
        }),
      }
      if (this.isBalance) {
        postData.balance = this.accountInfo.real
      }
      this.$emit('post:calc', { calcData: postData, checkCoupons: this.checkCoupons })
    },
    _account() {
      account.accountInfo({ userId: this.userInfo.id }).then((res) => {
        this.accountInfo = res.data
      })
    },
    _coupon() {
      coupon
        .list({ userId: this.userInfo.id, page: 1, size: 1000 })
        .then((res) => {
          if (!res.data) {
            return
          }
          // 只能选择未使用优惠券
          res.data.forEach((coupon) => {
            const now = Date.now()
            if (coupon.status === 0 && now >= coupon.beginTime && now <= coupon.endTime) {
              this.coupons.push(coupon)
            }
          })
        })
    },
  },
}
</script>

<style lang="stylus" scoped>
.calc
  padding 0 30px
  .mask
    position fixed
    top 0
    bottom 0
    left 0
    right 0
    z-index 1000
    background-color rgba(0, 0, 0, 0.3)
    .mask-content
      position absolute
      left 0
      right 0
      bottom 0
      background-color #f8f8f8
      padding 20px 0 0 0
      .btn-cancel
        position absolute
        top 0
        right 0
        padding 15px 20px
        font-size 24px
        color #999999
        cursor pointer
  .title
    width 100%
    height 79px
    line-height 79px
    font-family PingFangSC-Regular
    font-size 32px
    font-weight normal
    font-stretch normal
    letter-spacing 0px
    color #999999
    &::after
      content ' '
      display block
      width 100%
      height 1px
      transform scaleY(0.7)
      background-color #E8E8E8
  .item
    width 100%
    height 80px
    display flex
    justify-content space-between
    align-items center
    .left
      font-size 30px
    .right
      display flex
      align-items center
      input
        visibility hidden
        width 1px
      .icon-select
        display inline-block
        width 36px
        height 36px
        margin-left 8px
      .active, .disabled
        font-size 26px
        font-weight normal
        font-stretch normal
        letter-spacing 0px
        padding 0 10px
      .active
        background-color #ff4126
        color #ffffff
        border-radius 25px
      .icon-more
        display inline-block
        height 36px
        width auto
        margin-left 10px
      .sale
        display inline-block
        margin-right 10px
        color #ff4126
        font-size 26px
      .disabled, .gray
        display inline-block
        color #999999
      .login
        color #ff4126
</style>