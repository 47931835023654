/*
 *微信授权登录
 *
 * */
import WxApi from '@/models/wx'
const wxApi = new WxApi()

//  微信授权地址
let wx_login_url = async (verificationCode) => {
  let { data } = await wxApi.getAppId()
  let url = encodeURIComponent(window.location.href)
  return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${data.appid}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=${verificationCode}#wechat_redirect`
}

const wxLogin = async (verificationCode) => {
  let url = await wx_login_url(verificationCode)
  window.location.href = url
}

export default wxLogin
