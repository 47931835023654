<template>
  <div class="user">
    <div class="item">
      <span class="item-label">账户</span>
      <div class="item-value">{{ user.phone }}</div>
    </div>
    <div class="item">
      <span class="item-label">姓名</span>
      <div class="item-value">{{ user.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: [Object],
  },
}
</script>

<style lang="stylus" scoped>
.user
  padding 30px
  z-index 102
  position relative
  .item
    display flex
    flex-direction row
    line-height 46px
    font-size 26px
    color #666666
    margin-bottom 30px
    &:last-child
      margin-bottom 10px
    .item-label
      flex 0 0 60px
      font-size 26px
      color #666666
    .item-value
      flex 1
</style>